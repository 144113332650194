export const updateFormMixin = {
  data () {
    return {
      id: '',
      modifyData: null,
      apifunc: () => {},
      confirmTxt: 'Create'
    }
  },
  methods: {
    async initInfo (getApi, dataKey, deleteKey) {
      if (!this.id) return
      try {
        const res = await getApi(this.id)
        if (res.data[dataKey]) {
          if (res.data[dataKey][deleteKey] && deleteKey) {
            res.data[dataKey][deleteKey + '_id'] = res.data[dataKey][deleteKey].id
            delete res.data[dataKey][deleteKey]
          }
          this.modifyData = res.data[dataKey]
        }
      } catch (error) {
        
      }
    },
    initData (createapi, updateApi) {
      this.id = this.$route.query.id
      this.apifunc = createapi
      if (this.id) {
        this.confirmTxt = 'Update'
        this.apifunc = (data) => updateApi(this.id, data)
      }
    }
  }
}