export default {
  formDataGroups: [
    {
      title: 'General Information',
      name: 0,
      formDataItems: [
        {
          span: 20,
          formtype: 'input',
          prop: 'name',
          label: 'Discharge Destination',
          placeholder: 'Please enter the discharge destination',
          object: {
            required: true,
            rules: [
              { required: true, message: 'Please enter the discharge destination', trigger: 'blur' }
            ]
          }
        }
      ]
    }
  ]
}