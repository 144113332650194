<template>
  <el-card>
    <CommonForm
      label-position="right"
      label-width="160px"
      :formDataGroups="formDataGroups"
      :confirm-txt="confirmTxt"
      createDataType="destination"
      :apifunc="apifunc"
      :modifyData="modifyData"
    />
  </el-card>
</template>

<script>
import CommonForm from '@/components/CommonForm/index.vue'
import { updateFormMixin } from '@/mixin/updateFormMixin'
import {
  getDestinationInfo,
  createDestination,
  updateDestinationInfo
} from '@/services'
import jsonData  from './jsonData'
export default {
  components: {
    CommonForm
  },
  mixins: [updateFormMixin],
  data () {
    return {
      formDataGroups: jsonData.formDataGroups
    }
  },
  created () {
    this.initData(createDestination, updateDestinationInfo)
    this.initInfo(getDestinationInfo, 'destination')
  }
}
</script>